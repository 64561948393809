export const application = {
  registeredScripts: [],
  register: (script, meta) => {
    application.registeredScripts.push(meta.url)

    document.addEventListener('DOMContentLoaded', function () {
      const pageScript = script()
      if (pageScript) { pageScript() }
    })
  }
}
